import React,{ useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { whiteLogo } from '../../components/imageImport'
import StyleSwitcher from '../../components/StyleSwitcher'
import Swal from 'sweetalert2'
import axios from 'axios';


function ResetPassword() {
  const navigate = useNavigate()
  const [email,setEmail]= useState('');

  const handleSubmit= (e)=>{ 
e.preventDefault();
    axios.post('https://nftbe.rbdemo.live/api/v1/auth/forgotpassword', {
        email: email
      
      })
      .then(function (response) {
        Swal.fire(
          'Success',
          'Please Check your email',
          'success'
        )
       // navigate(`/ChangePassword`)
      }).catch(function (error) {
        Swal.fire(
          'error',
          'Server Error Please Try again later',
          'error'
        )
      });












  }


  return (
    <>
    <div className="round-effect">
        <div className="primary-round opacity-3"></div>
        <div className="gradient-round opacity-3"></div>
      </div>

      <div className="back-to-home">
        <a
          href=""
          onClick={e => e.preventDefault()}
          className="back-button btn btn-pills btn-sm btn-icon btn-primary"
        >
          <FiArrowLeft className="icons" />
        </a>
      </div>

      {/*  Hero Start */}
      <section className="position-relative">
        
        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                {/*  Start Logo */}
                <div className="text-center">
                  <a href="">
                    <img src={whiteLogo} alt="" height="100" />
                  </a>
                </div>
                {/*  End Logo */}

                {/*  Start Content */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form>
                      <h5 className="mb-3">Reset Your Password</h5>

                      <p className="text-muted">
                        Please enter your email address. You will receive a link
                        to create a new password via email.
                      </p>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder="name@example.com"
                              onChange={e=>{setEmail(e.target.value)}}
                            />
                            <label htmlFor="floatingInput">Email address</label>
                          </div>
                        </div>
                        {/* end col*/}

                        <div className="col-12">
                          <button
                            className="btn btn-primary rounded-md w-100"
                            type="submit"
                            onClick={e=>{handleSubmit(e)}}
                          >
                            Send
                          </button>
                        </div>
                        {/* end col*/}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Remember your password ?{' '}
                            </span>
                            <a
                              href="/login"
                              onClick={e => {
                                e.preventDefault()
                                navigate('/login')
                              }}
                              className="text-dark fw-bold"
                            >
                              Sign in
                            </a>
                          </small>
                        </div>
                        {/* end col*/}
                      </div>
                      {/* end row*/}
                    </form>
                  </div>
                </div>
                {/*  End Content */}

                {/*  Start Footer */}
                {/*<div className="text-center">
                  <small className="mb-0 text-light title-dark">
                  © Copyrights 2022 &nbsp;
                    <a
                      href="https://www.ascension-holding.com/"
                      target="_blank"
                      className="text-reset"
                    >
                      Ascension
                    </a>
                    .
                  </small>
                </div>*/}
                {/*  End Footer */}
              </div>
            </div>
            {/* end col*/}
          </div>
          {/* end row*/}
        </div>
        {/* end container*/}
      </section>
      {/* end section*/}
      {/*  Hero End */}

    
    </>
  )
}

export default ResetPassword
