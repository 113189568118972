import React,{ useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import { bg01, office } from '../../components/imageImport'
import StyleSwitcher from '../../components/StyleSwitcher'
import axios from "axios";
import Swal from 'sweetalert2'
const ConfirmeMail = () => {
  const navigate = useNavigate()

    useEffect(() => {
        const queryString = window.location.search;
        var ret = queryString.replace("?", "");

    let url=    `https://nftbe.rbdemo.live/api/v1/auth/confirmemail?${ret}`;
      axios.get(url).then(resp => {

        if(resp.data.success){

            Swal.fire(
                'Success',
                'you have successfully registered',
                'success'
              )
        }
        else{
           // alert(resp.data.error);  
            Swal.fire(
                'Error',
                resp.data.error,
                'error'
              )
        }

      //console.log(resp.data);
       });

    },[]);

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Thank You....
                </h5>
               
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/index"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/index')
                    }}
                  >
                    Superex
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Confirm 
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start Section */}
      <section className="section">
        <div className="container">
          <div className="row">

          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

</section>
   
      {/*end container*/}
      {/* End Section */}

      {/* footer */}
      <Footer />

      {/* Style switcher  */}
    </>
  )
}

export default ConfirmeMail
