import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Countdown from "react-countdown";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import StyleSwitcher from "../../components/StyleSwitcher";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Jazzicon from '@raugfer/jazzicon';
import SearchBar  from "../../components/SearchBar"
import Image from '../../components/Image'
import {
  bg01,
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  item7,
  item8,
  item9,
  item10,
  gif1,
  gif2,
  gif3,
  gif4,
  gif5,
  gif6,
  cta,
  client05,
  client06,
  client08,
  ico08,
} from "../../components/imageImport";

import { ethers } from "ethers";

import web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import Market from "../../artifacts/contracts/NFTMarket.sol/NFTMarket.json";
import NFT from "../../artifacts/contracts/NFT.sol/NFT.json";
import { nftmarketaddress, nftaddress } from "../../config";
import {NFTContext} from "../../context/NFTContext";

import Choices from "choices.js";
import { map } from "jquery";
import "../../common.css";

import Swal from 'sweetalert2'

const ExploreTwo = () => {
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(true);
  const {nftCurrency,currentAccount} =useContext(NFTContext);

  const AuctionData = [
    {
      image: item1,
      title: "CyberPrimal 042 LAN",
      id: "",
      type: "Arts",
      hash: 1,
    },
    {
      image: gif2,
      title: "Crypto Egg Stamp #5",
      id: "",
      type: "Games",
      hash: 2,
    },
    {
      image: item2,
      title: "Colorful Abstract Painting",
      id: "June 03, 2022 5:3:1",
      type: "",
      hash: 3,
    },
    {
      image: item3,
      title: "Liquid Forest Princess",
      id: "",
      type: "",
      hash: 4,
    },
    {
      image: gif3,
      title: "Spider Eyes Modern Art",
      id: "June 10, 2022 1:0:1",
      type: "GIFs",
      hash: 5,
    },
    {
      image: item4,
      title: "Synthwave Painting",
      id: "",
      type: "",
      hash: 6,
    },
    {
      image: item5,
      title: "Valkyrie Abstract Art",
      id: "",
      type: "",
      hash: 7,
    },
    {
      image: item6,
      title: "Dodo hide the seek",
      id: "",
      type: "",
      hash: 8,
    },
    {
      image: item7,
      title: "Rainbow Style",
      id: "June 18, 2022 1:2:1",
      type: "Music",
      hash: 9,
    },
    {
      image: item8,
      title: "Running Puppets",
      id: "",
      type: "Gallery",
      hash: 10,
    },
    {
      image: item9,
      title: "Loop Donut",
      id: "July 01, 2022 1:6:6",
      type: "Video",
      hash: 11,
    },
  ];
  const toggleSwitcher = () => {
    var i = document.getElementById("style-switcher");
    if (i) {
      if (i.style.left === "-189px") {
        i.style.left = "0px";
      } else {
        i.style.left = "-189px";
      }
    }
  };
  const location = useLocation();

  function buildDataUrl(address: string) {
    return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
  }


  useEffect(() => {
    //new Choices("#choices-criteria");
    var singleCategorie = document.getElementById("choices-type");
    if (singleCategorie) {
      new Choices("#choices-type");
    }

    setTimeout(() => {
      if (location?.pathname === "/index-two-dark-rtl") {
        document.getElementById("theme-opt").href =
          "./css/style-dark-rtl.min.css";
      } else if (location?.pathname === "/explore-two") {
        document.getElementById("theme-opt").href = "./css/style.min.css";
      } else if (location?.pathname === "/index-two-rtl") {
        document.getElementById("theme-opt").href = "./css/style-rtl.min.css";
      } else {
        document.getElementById("theme-opt").href = "./css/style-dark.min.css";
      }
      toggleSwitcher(false);
    }, 100);
  }, [location?.pathname]);

  {
    /*useEffect(() => {
    new Choices('#choices-criteria')
    var singleCategorie = document.getElementById('choices-type')
    if (singleCategorie) {
      new Choices('#choices-type')
    }
  }, [])*/
  }

  //nft related data
  const [nftsall, setNftsall] = useState([]);
  const [nftsCopy, setNftsCopy] = useState([]);
  const [loadedall, setLoadedall] = useState("not-loaded");
  const [activeSelect, setActiveSelect] = useState('Recently Added');
  async function loadNFTsall() {
    const url =
      "https://eth-goerli.alchemyapi.io/v2/9ctKkVZf-pCbOXxV3hT-2OhSm97FAmJg";
    const provider = new ethers.providers.JsonRpcProvider(url);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
    const marketContract = new ethers.Contract(
      nftmarketaddress,
      Market.abi,
      provider
    );
    const data = await marketContract.fetchMarketItems();

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = web3.utils.fromWei(i.price.toString(), "ether");
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.data.image,
          title: meta.data.name,
          meta: meta,
        };
        return item;
      })
    );
    console.log("items: ", items);
    setNftsall(items);
    SetLoading(false);
    setLoadedall("loaded");
  }

  // async function buyNft(nft) {
  //   const web3Modal = new Web3Modal({
  //     // network: "mainnet",
  //     cacheProvider: true,
  //   });
  //   const connection = await web3Modal.connect();
  //   const provider = new ethers.providers.Web3Provider(connection);
  //   const signer = provider.getSigner();
  //   const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer);

  //   console.log('price nft'+nft.price.toString());

  //   const price = web3.utils.toWei(nft.price.toString(), "ether");

  //   console.log("price: ", price);

  //   const transaction = await contract.createMarketSale(
  //     nftaddress,
  //     nft.tokenId,
  //     {
  //       value: price,
  //     }
  //   );
  //   await transaction.wait();
  //   loadNFTsall();
  // }

  //end
  const { fetchNFTs,buyNft  } = useContext(NFTContext);
  const [loader, setLoader] = useState('');
  useEffect(() => {

    const queryString = window.location.search;
    var ret = queryString.replace("?", "");
    let filterd=[];
    if(ret !=''){
      //need modify
      setLoader(<p className="text-gradient-info animate-charcter">PLEASE WAIT...!</p>);
      fetchNFTs()
      .then((items) => {
        

         

items.map((item) => {
  fetch(item.tokenURI
  ,{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  )
    .then(function(response){
     // console.log(response)
      return response.json();
    })
    .then(function(myJson) {
      if(myJson.attributes[0].collection===decodeURI(ret)){

        filterd.push(item);

        setNftsall(filterd);
      
         setNftsCopy(filterd);
      }
      console.log(myJson.attributes[0].collection);

     
     
    });

    

})

setTimeout(function() {
  filterd.length > 0 ?  setLoader(''):setLoader('No result found');
}, 4000);








        //  console.log(items.tokenURI.attributes.collection);

        //  if(items.tokenURI.attributes.collection !== undefined){
        //   alert(items.tokenURI.attributes.collection);
        //  }
       // setIsLoading(false);
      });
  
SetLoading(false);
    }
    else{
      fetchNFTs()
      .then((items) => {
       
        setNftsall(items.reverse());       

        setTimeout(function() {
          if(items.length <= 0){           
            SetLoading(false);
            Swal.fire(
              '',
              'Currently there are no items found in the marketplace.',
              'warning'
            ).then(function() {
              window.location = "/";
            });
          } else{           
            SetLoading(false);
          }          
        }, 5000);
        
         setNftsCopy(items);
       // setIsLoading(false);
      });

    }

   

   // loadNFTsall();
  }, []);



  useEffect(() => {
    const sortedNfts = [...nftsall];

    switch (activeSelect) {
      case 'Price (low to high)':
        setNftsall(sortedNfts.sort((a, b) => a.price - b.price));
        break;
      case 'Price (high to low)':
        setNftsall(sortedNfts.sort((a, b) => b.price - a.price));
        break;
      case 'Recently added':
        setNftsall(sortedNfts.sort((a, b) => b.tokenId - a.tokenId));
        break;
      default:
        setNftsall(nftsall);
        break;
    }
  }, [activeSelect]);







  const onHandleSearch = (value) => {
    const filteredNfts = nftsall.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));
   console.log({value})
    // if (filteredNfts.length === 0) {
    //   setNftsall([]);
    // } else {
    //   setNftsall(filteredNfts);
    // }


    if (value !='' && filteredNfts.length === 0) {
      setNftsall([]);
    }
    else if (value===''){
      setNftsall(nftsCopy);
    }
    else {
      setNftsall(filteredNfts);
    }


  };

  const onClearSearch = () => {
    if (nftsall.length && nftsCopy.length) {
      setNftsall(nftsCopy);
    }
  };


  const [cls,setCls]=useState('no');

  const addlike=(e,token,currentAccount)=>{


    e.preventDefault();
  setCls('active-wishlist');

    if(localStorage.getItem(token)){
      localStorage.removeItem(token);
    }
    else{
      localStorage.setItem(token, currentAccount);
    }
  




  }

  return (
    <>
      {/* Navbar */}
      <Navbar />
      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Marketplace
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Explore the latest NFTs digital product
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start */}

      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="features-absolute">
                <div className="row justify-content-center" id="reserve-form">
                  <div className="col-xl-10 mt-lg-5">
                    <div className="card bg-white feature-top border-0 shadow rounded p-3">
                    <SearchBar activeSelect={activeSelect} setActiveSelect={setActiveSelect} handleSearch={onHandleSearch} clearSearch={onClearSearch} />
                    </div>
                  </div>
                  {/*ed col*/}
                </div>
                {/*end row*/}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="container">
          <div className="row">
          {loading?<div className="text-center"><div class="lds-facebook"><div></div><div></div><div></div></div></div>:
            <div className="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-1 justify-content-left">
                {nftsall.length===0?<div className="center-div">{loader}</div>:nftsall?.map((data) => {
                  return (
                    <div className="pt-2 mt-4">
                      <div className="card nft-items nft-default rounded-md shadow overflow-hidden mb-1 p-3">
                        <div className="d-flex justify-content-between">
                          <div className="img-group">
                            <a
                              href="/creator-profile"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/creator-profile");
                              }}
                              className="user-avatar"
                            >
                              <img
                                src={buildDataUrl(data.seller)}
                                alt="user"
                                className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                              />
                            </a>
                           
                             
                          </div>

                          <span className="like-icon shadow-sm">
                            <a
                              href=""
                              onClick={(e) => { addlike(e,data.tokenId,currentAccount) }}
                              className="text-muted icon"
                            >

{localStorage.getItem(data.tokenId)===currentAccount? <i className={`mdi mdi-18px mdi-heart mb-0 active-wishlist` } ></i>: <i className={`mdi mdi-18px mdi-heart mb-0` }   onClick={(e) => { e.currentTarget.classList.toggle('active-wishlist')}}></i>

}
                             


                            </a>
                          </span>
                        </div>
                        <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                          <a
                            // href="/item-detail-one"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/item-detail-one?${ base64_encode(JSON.stringify(data))}`);
                            }}
                          >
                           
                            
                            <Image imageUrl={data?.image} />
                          </a>
                        </div>

                        <div className="card-body content position-relative p-0 mt-3">
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/item-detail-one?${base64_encode(JSON.stringify(data))}`);
                            }}
                            className="title text-dark h6"
                          >
                            {data?.name}
                          </a>

                          <div className="d-flex justify-content-between mt-2">
                            <div className="item-left-content">
                              <small className="rate fw-bold">
                                {data.price} {nftCurrency}
                              </small>
                            </div>
                            <div className="item-right-content">
                              <a
                                className="btn btn-icon btn-pills btn-outline-dark me-2"
                                href={`https://goerli.etherscan.io/token/0x09059EA3100834ee8aE9479c53AE688da0673136`}
                                title="View On PolygonScan"
                                alt="View On PolygonScan"
                              >
                                <img
                                  style={{ width: "10px" }}
                                  src={ico08}
                                />
                              </a>
                              <button
                                className="btn btn-icon btn-pills btn-primary "
                                onClick={() => buyNft(data)}
                              >
                                <i className="uil uil-shopping-cart fs-6"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>}

            {/*
            
            {AuctionData?.map(data => {
              return (
                <div className="col mt-4 pt-2" key={data?.title}>
                  <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                    <div className="d-flex justify-content-between">
                      <div className="img-group">
                        <a
                          href="/creator-profile"
                          onClick={e => {
                            e.preventDefault()
                            navigate('/creator-profile')
                          }}
                          className="user-avatar"
                        >
                          <img
                            src={client08}
                            alt="user"
                            className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                          />
                        </a>
                        <a
                          href="/creator-profile"
                          onClick={e => {
                            e.preventDefault()
                            navigate('/creator-profile')
                          }}
                          className="user-avatar ms-n3"
                        >
                          <img
                            src={client05}
                            alt="user"
                            className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                          />
                        </a>
                        <a
                          href="/creator-profile"
                          onClick={e => {
                            e.preventDefault()
                            navigate('/creator-profile')
                          }}
                          className="user-avatar ms-n3"
                        >
                          <img
                            src={client06}
                            alt="user"
                            className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                          />
                        </a>
                      </div>

                      <span className="like-icon shadow-sm">
                        <a
                          href=""
                          onClick={e => e.preventDefault()}
                          className="text-muted icon"
                        >
                          <i className="mdi mdi-18px mdi-heart mb-0"></i>
                        </a>
                      </span>
                    </div>

                    <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                      <a
                        // href="/item-detail-one"
                        onClick={e => {
                          e.preventDefault()
                          navigate(`/item-detail-one?${ base64_encode(data?.image)}`)
                        }}
                      >
                        <img src={data?.image} className="img-fluid" alt="" />
                      </a>
                      {data?.type && (
                        <div className="position-absolute top-0 start-0 m-2">
                          <a
                            href=""
                            onClick={e => e.preventDefault()}
                            className="badge badge-link bg-primary"
                          >
                            {data?.type}
                          </a>
                        </div>
                      )}
                      <div
                        className={`${data?.id ? '' : 'hide-data'
                          } position-absolute bottom-0 start-0 m-2 bg-gradient-primary text-white title-dark rounded-pill px-3`}
                      >
                        <i className="uil uil-clock"></i>{' '}
                        <Countdown
                          date={data?.id}
                          renderer={({ days, hours, minutes, seconds }) => (
                            <span>
                              {days}:{hours}:{minutes}:{seconds}
                            </span>
                          )}
                        />
                       
                      </div>
                    </div>

                    <div className="card-body content position-relative p-0 mt-3">
                      <a
                        onClick={e => {
                          e.preventDefault()
                          navigate(`/item-detail-one?${ base64_encode(data?.image)}`)
                        }}
                        className="title text-dark h6"
                      >
                        {data?.title}
                      </a>

                      <div className="d-flex justify-content-between mt-2">
                        <small className="rate fw-bold">20.5 ETH</small>
                        <small className="text-dark fw-bold">1 out of 10</small>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          */}
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="row justify-content-center mt-4">
            {/* <div className="col">
              <div className="text-center">
                <a
                  href=""
                  onClick={(e) => e.preventDefault()}
                  className="btn btn-primary rounded-md"
                >
                  <i className="uil uil-process mdi-spin me-1"></i> Load More
                </a>
              </div>
              </div>*/}
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}

      {/* CTA End */}
      {/* footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  );
};

export default ExploreTwo;
