import React, { useEffect, useState } from "react";

const Image = ({imageUrl}) => {
    const [img, setImg] = useState('https://c.tenor.com/XasjKGMk_wAAAAAC/load-loading.gif');

    const fetchImage = async () => {
      const res = await fetch(imageUrl);
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImg(imageObjectURL);
    };
  
    useEffect(() => {
      fetchImage();
    }, []);
  
  return (
    <img
                              src={img}
                              className="img-fluid"
                              alt=""
       />
  )
}

export default Image
