import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowRight, FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
// image import
import {
  bg1, bg2, bg3,
  client01, client02, client03, client04, client05, client06, client07, client08,
  bg01,
  about,
  ico01,ico02,ico03,ico04,ico05,ico06,ico07,
} from "../../components/imageImport";
import '../../common.css'

const AboutUs = () => {
  const navigate = useNavigate()
  const clientRecord = [
    {
      image: client01,
      name: 'Sir Gavin',
      position: 'Founder',
    },
    {
      image: client02,
      name: 'Brad Burton',
      position: 'Founder Networking',
    },    
    {
      image: client03,
      name: 'Meddy Sahebi',
      position: 'Founder Tokenizz',
    },
    {
      image: client04,
      name: 'David Weir',
      position: 'Paralympic Medalist CBE',
    },
    {
      image: client05,
      name: 'Connortierney',
      position: 'Former BKB World Champion',
    },
    {
      image: client06,
      name: 'Mark Redknapp',
      position: 'Founder',
    },
    {
      image: client07,
      name: 'Andrew Mcadams',
      position: 'Founder',
    },
    {
      image: client08,
      name: 'Roy Shanks',
      position: 'Founder',
    },
  ]

  const blogRecord = [
    {
      image: bg1,
      title: 'Mindfulness Activities for Kids & Toddlers with NFT',
      createdBy: '@callyjoe',
      type: 'Arts',
    },
    {
      image: bg2,
      title: 'Save Thousands Of Lives Through This NFT',
      createdBy: '@kristyhoney',
      type: 'Illustration',
    },
    {
      image: bg3,
      title: 'A place where technology meets craftsmanship',
      createdBy: '@pandaone',
      type: 'Music',
    },
  ]

  return (
    <>
      <Navbar />
      {/* Start Home */}
      <section
        className="bg-half-100 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Arts & Crafts
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  NFT marketplace where anything is possible and all are welcome
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}


      <section className="section text-extra-dark-gray bg-section-white">
            <div class="container">               
                <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">
                 
                    <div class="col md-margin-30px-bottom xs-margin-15px-bottom last-paragraph-no-margin" >
                        <div class="feature-box">
                            <div class="content">
                                <img src='http://redberyldigital.teckypeppers.com/wp-content/uploads/2022/04/Custom-Solution-2.png' alt="Digital Advisory"/>
                                <div class="text-medium text-capitalize pb-2">Digital Advisory</div>
                                <p class="w-85 mx-auto md-w-100 text-small">An advisory based service focussing on identifying where and how new technology fits into your digital transformation strategy</p>
                            </div>        
                        </div>
                    </div>
                   
                    <div class="col md-margin-30px-bottom xs-margin-15px-bottom last-paragraph-no-margin">
                        <div class="feature-box">
                            <div class="content">
                            <img src='http://redberyldigital.teckypeppers.com/wp-content/uploads/2022/04/Consulting-2.png' alt="Blockchain Consulting"/>
                                <div class="text-medium text-capitalize pb-2">Blockchain Consulting</div>
                                <p class="w-85 mx-auto md-w-100 text-small">A consultancy-led service focusing on your business’s challenges, the discovery of blockchain innovation opportunities, and solution advisory</p>
                            </div>
                        </div>
                    </div>
                  
                    <div class="col last-paragraph-no-margin">
                        <div class="feature-box">
                            <div class="content">
                            <img src='http://redberyldigital.teckypeppers.com/wp-content/uploads/2022/04/Building-Production-2.png' alt="Blockchain Engineering"/>
                                <div class="text-medium text-capitalize pb-2">Blockchain Engineering</div>
                                <p class="w-85 mx-auto md-w-100 text-small">Custom blockchain development and integration services to build anything from PoCs to large-scale solution deployments</p>
                            </div>   
                        </div>
                    </div>
                   
                </div>
            </div>            
        </section>


      <section id="about_players" class="section pb-0 ds">   
				<div class="container">
        <div class="row justify-content-center align-items-center pb-5 text-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="heading-title pb-4">
                    
                    <h1>What Makes Us Different</h1>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-10 text-center">
                <p class="text-center text-md-center">Create an account to manage / fund / publish mediate and create revenue / sponsorship / investment in Arts & Crafts only dedicated platform marketplace
</p>
            </div>
        </div>
					<div class="row">
						<div class="col-xs-12 col-md-12 col-lg-4 fw-column">
							<div class="divider-lg-30"></div>
							<div class="icon-box icon_left mobile-center">
								<div class="box_icon color-main">
									<img src={ico01} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
										<a href="#">Funding</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_left mobile-center">
								<div class="box_icon color-main">
									<img src={ico03} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">New Audiences</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_left mobile-center">
								<div class="box_icon color-main">
									<img src={ico02} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">Smart Contracts</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
						</div>

						<div class="col-xs-12 col-md-12 col-lg-4 text-center fw-column align-self-end">
							<div class="divider-50 d-lg-none"></div>
							<div class="img-wrap avatar-player mt--30">
								<img src={ico07} alt=""/>
							</div>
							<div class="divider-50 d-lg-none"></div>
						</div>

						<div class="col-xs-12 col-md-12 col-lg-4 fw-column">
							<div class="divider-lg-30"></div>
							<div class="icon-box icon_right mobile-center">
								<div class="box_icon color-main">
									<img src={ico04} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">Sponsorship</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_right   mobile-center">
								<div class="box_icon color-main">
									<img src={ico05} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">Virtual Training</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_right   mobile-center">
								<div class="box_icon color-main">
									<img src={ico06} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">NFTS</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

        <div class="header">
<div>
<svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="waves"
      preserveAspectRatio="none"
      viewBox="0 24 150 28"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
        ></path>
      </defs>
      <g className="parallax">
        <use x="48" fill="rgba(255, 255, 255, 0.7)" xlinkHref="#gentle-wave"></use>
        <use
          x="48"
          y="3"
          fill="rgba(255, 255, 255, 0.5)"
          xlinkHref="#gentle-wave"
        ></use>
        <use
          x="48"
          y="5"
          fill="rgba(255, 255, 255, 0.3)"
          xlinkHref="#gentle-wave"
        ></use>
        <use x="48" y="7" fill="#000" xlinkHref="#gentle-wave"></use>
      </g>
    </svg>
</div>
</div>

			</section>

    

      {/* Footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default AboutUs
