import React ,{ useState, useEffect } from 'react'

const SearchBar = ({ activeSelect, setActiveSelect, handleSearch, clearSearch }) => {
    const [search, setSearch] = useState('');
    const [toggle, setToggle] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedSearch), 1000);
    
        return () => clearTimeout(timer);
      }, [debouncedSearch]);
    
      useEffect(() => {
        if (search) {
          handleSearch(search);
        } else {
          clearSearch();
        }
      }, [search]);











      
  return (
    <form action="#">
                        <div className="registration-form text-dark text-start">
                          <div className="row g-lg-0">
                            <div className="col-lg-3 col-md-6">
                              <div className="filter-search-form position-relative filter-border">
                                <i className="uil uil-search icons"></i>
                                <input
                                  name="name"
                                  type="text"
                                  id="search-keyword"
                                  className="form-control filter-input-box bg-light border-0"
                                  placeholder="Search your keaywords"
                                  onChange={(e) => setDebouncedSearch(e.target.value)}
                                  value={debouncedSearch}
                                />
                              </div>
                            </div>
                            {/*end col*/}

                            {/* <div className="col-lg-3 col-md-6 mt-3 mt-md-0">
                              <div className="filter-search-form position-relative filter-border">
                                <i className="uil uil-usd-circle icons"></i>
                                <select
                                  className="form-select"
                                  data-trigger
                                 
                                  id="choices-criteria"
                                  aria-label="Default select example"
                                  defaultValue="Auction Product"
                                >
                                  <option value="1">Auction Product</option>
                                  <option value="2">On Sale</option>
                                  <option value="3">Offers</option>
                                </select>
                              </div>
                            </div> */}
                            {/*end col*/}

                            <div className="col-lg-6 col-md-8 mt-3 mt-lg-0">
                              <div className="filter-search-form position-relative filter-border">
                                <i className="uil uil-window icons"></i>
                                <select
                                  className="form-select "
                                
                                  name="choices-type"
                                  id="choices-type"
                                  aria-label="Default select example"
                                  defaultValue="Recently added"
                                  onChange={(e) => setActiveSelect(e.target.value)}
                                >

{['Recently added', 'Price (low to high)', 'Price (high to low)'].map((item) => (
            

<option value={item}  key={item}  onChange={() => setActiveSelect(item)}   >{item}</option>
            ))}



                                 
                                 
                                </select>
                              </div>
                            </div>
                            {/*end col*/}

                            <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                              <input
                                type="submit"
                                id="search"
                                name="search"
                                style={{ height: 60 }}
                                className="btn btn-primary rounded-md searchbtn submit-btn w-100"
                                value="Search"
                                disabled
                                onChange={(e) => setDebouncedSearch(e.target.value)}
                             
                              />
                            </div>
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </div>
                        {/*end container*/}
                      </form>
  )
}

export default SearchBar
