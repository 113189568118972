import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowRight, FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import '../../common.css'
// image import
import {
  bg1, bg2, bg3,
  client01, client02, client03, client04, client05, client06, client07, client08,
  bg01,
  p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15,
  about,
} from "../../components/imageImport";

import StyleSwitcher from '../../components/StyleSwitcher'

const Partners = () => {
    const navigate = useNavigate()
  return (
    <>
    <Navbar />
    {/* Start Home */}
    <section
      className="bg-half-100 d-table w-100"
      style={{ background: `url(${bg01}) bottom` }}
    >
      <div className="bg-overlay bg-gradient-overlay-2"></div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="title-heading text-center">
              <h5 className="heading fw-semibold sub-heading text-white title-dark">
             Exhibited Artists
              </h5>
              <p className="text-white-50 para-desc mx-auto mb-0">
                NFT marketplace where anything is possible and all are welcome
              </p>
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}

        
      </div>
      {/*end container*/}
    </section>
    {/*end section*/}
    <div className="position-relative">
      <div className="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    {/* End Home */}

    {/* Start Section */}
    <section className="section team-members">
  
      <div className="container mt-10">
        <div className="row justify-content-center">
       
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                        <div class="square-holder h-item">
                        <img alt="" src="https://artlogic-res.cloudinary.com/w_800,h_800,c_fill,f_auto,fl_lossy,q_auto:low/assets/maddoxgallery/usr/images/artists/artwork_image_for_artists_page/items/8e/8ec3f7405a51400890a63be4f8ba5c52/dy0990.jpg" style={{ width: "100%" }}/>
                        <div className='artist-name'>David Yarrow</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                        <div class="square-holder h-item">
                        <img alt="" src="https://artlogic-res.cloudinary.com/w_800,h_800,c_fill,f_auto,fl_lossy,q_auto:low/assets/maddoxgallery/usr/images/artists/artwork_image_for_artists_page/items/f3/f371cad85147457f855f571f8ddb7b64/four-foot-dissected-companion.jpg" style={{ width: "100%" }}/>
                        <div className='artist-name'>Kaws</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                        <div class="square-holder h-item">
                        <img alt="" src="https://artlogic-res.cloudinary.com/w_800,h_800,c_fill,f_auto,fl_lossy,q_auto:low/assets/maddoxgallery/usr/images/artists/artwork_image_for_artists_page/items/12/127075a547634a9f845976212f355975/bb1.png" style={{ width: "100%" }}/>
                        <div className='artist-name'>Jerkface</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                        <div class="square-holder h-item">
                        <img alt="" src="https://artlogic-res.cloudinary.com/w_800,h_800,c_fill,f_auto,fl_lossy,q_auto:low/assets/maddoxgallery/usr/images/artists/artwork_image_for_artists_page/items/32/322ffc5e9243408292809ff3c1221f94/aw0003.jpg" style={{ width: "100%" }}/>
                        <div className='artist-name'>Andy Warhol</div>
                        </div>
                    </div>

                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                        <div class="square-holder h-item">
                        <img alt="" src="https://artlogic-res.cloudinary.com/w_800,h_800,c_fill/assets/maddoxgallery/usr/images/artists/artwork_image_for_artists_page/items/e2/e22c267c80ad417191aba8b1e46250aa/will-martyr.jpg" style={{ width: "100%" }}/>
                        <div className='artist-name'>Will Martyr</div>
                        </div>
                    </div>

                    <div class="col-6 col-sm-4 col-md-3 col-lg-3">
                        <div class="square-holder h-item">
                        <img alt="" src="https://artlogic-res.cloudinary.com/w_800,h_800,c_fill,f_auto,fl_lossy,q_auto:low/assets/maddoxgallery/usr/images/artists/artwork_image_for_artists_page/85/tc0081.jpg" style={{ width: "100%" }}/>
                        <div className='artist-name'>The Connor Brothers</div>
                        </div>
                    </div>
                  
         
          {/*end col*/}
        </div>
        {/*end row*/}

       
      </div>
      {/*end container*/}
    </section>
    {/*end section*/}
    {/* End Section */}

    {/* Footer */}
    <Footer />

    {/* Style switcher  */}
 
  </>
  )
}

export default Partners