import React,{ useEffect,useState } from "react";
import web3 from 'web3'
import axios from 'axios'
import Web3Modal from "web3modal"
import { ethers } from 'ethers'
import {MarketAddress, nftaddress,NFTMarketplace} from '../config'
import Market from "../artifacts/contracts/NFTMarket.sol/NFTMarket.json";
import NFT from "../artifacts/contracts/NFT.sol/NFT.json";
import MarketABI from "../artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json";
import Swal from 'sweetalert2'

export const NFTContext = React.createContext();

const fetchContract = (signerOrProvider) => new ethers.Contract(MarketAddress, MarketAddressABI, signerOrProvider);
const MarketAddressABI = MarketABI.abi;
export const NFTProvider = ({ children }) => {
  const nftCurrency = 'ETH';
  const [currentAccount, setCurrentAccount] = useState('');
  const [isLoadingNFT, setIsLoadingNFT] = useState(true);

  const fetchNFTs = async () => {
    setIsLoadingNFT(false);
    const url =
    "https://goerli.infura.io/v3/e62b359d71714f77a5e2b4992fb55dd9";
    const provider = new ethers.providers.JsonRpcProvider(url);
    const contract = fetchContract(provider);

    const data = await contract.fetchMarketItems();

    const items = await Promise.all(data.map(async ({ tokenId, seller, owner, price: unformattedPrice }) => {
      const tokenURI = await contract.tokenURI(tokenId);
      const { data: { image, name, description } } = await axios.get(tokenURI);
      const price = ethers.utils.formatUnits(unformattedPrice.toString(), 'ether');

      return { price, tokenId: tokenId.toNumber(), id: tokenId.toNumber(), seller, owner, image, name, description, tokenURI };
    }));

    console.log(JSON.stringify(items));

    return items;
  };

  const fetchMyNFTsOrCreatedNFTs = async (type) => {
    setIsLoadingNFT(false);

    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    const contract = fetchContract(signer);
    const data = type === 'fetchItemsListed' ? await contract.fetchItemsListed() : await contract.fetchMyNFTs();
    //console.log('items1',data)
    const items = await Promise.all(data.map(async ({ tokenId, seller, owner, price: unformattedPrice }) => {
      const tokenURI = await contract.tokenURI(tokenId);
      const { data: { image, name, description } } = await axios.get(tokenURI);
      const price = ethers.utils.formatUnits(unformattedPrice.toString(), 'ether');
         // console.log('items1',items)
      return { price, tokenId: tokenId.toNumber(), seller, owner, image, name, description, tokenURI };
    }));

    return items;
  };

  const createSale = async (url, formInputPrice, isReselling, id) => {
    if(formInputPrice === ''){
      alert("Resell price should be more than USD 0");
    } else{
    setIsLoadingNFT(true);
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    const price = ethers.utils.parseUnits(formInputPrice, 'ether');
    const contract = fetchContract(signer);

    console.log({contract});
    const listingPrice = await contract.getListingPrice();

    const transaction = !isReselling
      ? await contract.createToken(url, price, { value: listingPrice.toString() })
      : await contract.resellToken(id, price, { value: listingPrice.toString() });

    
    await transaction.wait();
    setIsLoadingNFT(false);
    Swal.fire(
      'Success',
      'Transaction Successfully Completed.',
      'success'
    ).then(function() {
      window.location = "/explore-two";
    });
    }
  };

  const buyNft = async (nft) => {
    setIsLoadingNFT(true);
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(MarketAddress, MarketAddressABI, signer);

    const price = ethers.utils.parseUnits(nft.price.toString(), 'ether');
    const transaction = await contract.createMarketSale(nft.tokenId, { value: price });
    console.log({transaction})
    //setIsLoadingNFT(false);
    const receipt =await transaction.wait();
    
    if(!receipt){
      alert('error');
    } else{
      setIsLoadingNFT(false);
      Swal.fire(
        'Success',
        'Transaction Successfully Completed.',
        'success'
      ).then(function() {
        window.location = "/mynfts";
      });
    }

    //message
    //setIsLoadingNFT(false);
  };

  const connectWallet = async () => {
    if (!window.ethereum) return alert('Please install MetaMask.');

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    setCurrentAccount(accounts[0]);
    window.location.reload();
  };

  const checkIfWalletIsConnect = async () => {
    if (!window.ethereum) return alert('Please install MetaMask.');

    const accounts = await window.ethereum.request({ method: 'eth_accounts' });

    if (accounts.length) {
      setCurrentAccount(accounts[0]);
    } else {
      console.log('No accounts found');
    }
  };

  useEffect(() => {
   // checkIfWalletIsConnect();
   // createSale('test','0.001');
  }, []);

  return (
    <NFTContext.Provider value={{ nftCurrency, buyNft, createSale, fetchNFTs, fetchMyNFTsOrCreatedNFTs, connectWallet, currentAccount, isLoadingNFT }}>
      {children}
    </NFTContext.Provider>
  );
};