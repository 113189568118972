import React,{useEffect} from 'react'
import alanBtn from '@alan-ai/alan-sdk-web';

const useAlan = () => {
   
    useEffect(() => {
        alanBtn({
            key: '7a075ecac1d7d3df107a7a67deb5182f2e956eca572e1d8b807a3e2338fdd0dc/stage',
            onCommand: ({command,mode}) => {
              if (command === 'gomataverse') {
             
                window.location.href = 'https://spatial.io/s/Tokensport-Metaverse-62b99fe7a3641b00014d4300?share=840821616099253322';
               

              }
              else if(command === 'login'){
                window.location.href ='/login';

              }

              else if(command === 'logout'){
                localStorage.removeItem('token');
                window.location.href ='/login';

                
            }

            else if(command === 'marketplace'){
              
                window.location.href ='/explore-two';

                
            }
            else if(command === 'about'){
              
                window.location.href ='/aboutus';

                
            }

            else if(command === 'partners'){
              
                window.location.href ='/partners';

                
            }
            //
              
            }
        });
      }, []);
}

export default useAlan; 